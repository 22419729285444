import React from "react";
import { useAuthContext } from "../../contexts/AuthContext";
import App from "../../App";
//import CheckTokenChange from "../../services/CheckTokenChange";
import SignIn from '../../pages/SignIn';
import SignOutInactiveUser from "../inactivitycheck/checkinactivity";

function LoginCheck() {
  const { loggedIn } = useAuthContext();



  return <div>{loggedIn ? <App /> : <SignIn/>}</div>;
}

export default LoginCheck;
