import React, { useState, createContext, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import SignOutInactiveUser from "../components/inactivitycheck/checkinactivity";


const AuthContext = createContext();

const useAuthContext = () => {
	return useContext(AuthContext);
};

const AuthProvider = ({ children }) => {
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [loggedIn, setLoggedin] = useState(false);
	const [userId, setUserId] = useState();

	const Login = () => {
		setLoggedin(true);
	};

	const Logout = () => {
		//localStorage.removeItem("updatedcartstorage");
		//localStorage.removeItem("token");
		localStorage.removeItem("customerid");
		localStorage.removeItem("companyname");
		localStorage.removeItem("isdealer");
		localStorage.removeItem("historyorderstorage");
		localStorage.removeItem("historycartstorage");
		localStorage.removeItem("checktoken");
		localStorage.removeItem("IpAdresa");
		localStorage.removeItem("firmaemri");
	//	localStorage.clear;
		setLoggedin(false);
	};

	useEffect(() => {
		const token = localStorage.getItem("token");

		if (token) {
			setLoggedin(true);

		}
	}, []);

	//-----------------------Kontrollo tokeni a ka ndryshu------------------

	const token = localStorage.getItem("token");
	const tokentest = localStorage.getItem("checktoken");

	// const setToken1 = (value) => localStorage.setItem("token", value);

	useEffect(() => {
		const fetchData = () => {
			const token = localStorage.getItem("token");
			const tokentest = localStorage.getItem("checktoken");
			if (token !== tokentest) {
				Logout();
				console.log(token, tokentest);
			}
		};

		fetchData(); // Call fetchData immediately

		const intervalId = setInterval(() => {
			fetchData();
		}, 1800); // Replace 1000 with the desired interval in milliseconds

		// Cleanup the interval when the component unmounts
		return () => clearInterval(intervalId);
	}, [tokentest, token]);

	//--------------------------------------------------------------------------

	return (
		<AuthContext.Provider
			value={{
				setUserId,
				userId,
				loggedIn,
				Login,
				Logout,
				username,
				setUsername,
				password,
				setPassword,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};
AuthProvider.propTypes = {
	children: PropTypes.node,
};

export { AuthProvider, AuthContext, useAuthContext };
