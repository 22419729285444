import React, { useState} from "react";
import { useTranslation } from "react-i18next";
import CartPdf from "./CartPdf";
import GeneratePDF from "./GeneratePDF";

const CartPdfContent = () => {
  const { t } = useTranslation(); // Destructure the t function
 
  const [pagesaOptions, setPagesaOptions] = useState([]); // State to store pagesa options
  const [dergimiOptions, setDergimiOptions] = useState([]); // State to store dergimi options





  return (
    <div>
      <div display="flex">
        <div>
          <label htmlFor="pagesa">{t("paymentmethod")}: </label>
          <select id="pagesa">
            {pagesaOptions.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="dergimi">{t("deliverymethod")}: </label>
          <select id="dergimi" >
            {dergimiOptions.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
      </div>
      <CartPdf />
      {/*<CartPdfRemoved />*/}
    </div>
  );
};

const CartPDFGenerator = () => {
  return (
    <div>
      <GeneratePDF content={<CartPdfContent />} />
    </div>
  );
};

export default CartPDFGenerator;
