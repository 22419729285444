import React, { useEffect, useRef, useState } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import ConfirmationDialog from "./ConfirmationDialog";
import CartPdf from "./CartPdf";
import { useCartContext } from "../../contexts/CartContext";

const GeneratePDF = () => {
  const { t } = useTranslation();
  const pdfRef = useRef(null);
  const { pagesaMenyra, setPagesaMenyra, dergesaMenyra, setDergesaMenyra } = useCartContext();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const pagesaOptions = [t("cash", { defaultValue: "Кеш" }), t("invoice", { defaultValue: "Фактура" }), t("sootkup", { defaultValue: "Самоподиганње" })];
  const dergimiOptions = [`${t("pickup")}`,`${t("taksikombi")}`, `${t("bus")}`, `${t("cargo")}`, `${t("gls")}`];

  const generatePDF = () => {
    setIsDialogOpen(true);
  };

  const handleConfirm = () => {
    const input = pdfRef.current;
    const dpi = 300;

    html2canvas(input, { scale: dpi / 96 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/jpeg", 1.0);
      const pdf = new jsPDF("p", "mm", "a4");
      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      pdf.addImage(imgData, "JPEG", 0, 0, imgWidth, imgHeight);
      pdf.save("cart.pdf");
    });

    setIsDialogOpen(false);
  };

  const handleCancel = () => {
    setIsDialogOpen(false);
  };

  const handlePagesaChange = (e) => {
    const selectedOption = e.target.value;
    setPagesaMenyra(selectedOption);
  };

  const handleDergimiChange = (e) => {
    const selectedOption = e.target.value;
    setDergesaMenyra(selectedOption);
  };

  const handlePagesaBlur = (e) => {
    const selectedOption = e.target.value;
    setPagesaMenyra(selectedOption);
  };

  const handleDergimiBlur = (e) => {
    const selectedOption = e.target.value;
    setDergesaMenyra(selectedOption);
  };

  return (
    <div>
      <div>
        <label htmlFor="pagesa">{t("paymentmethod")}:</label>
        <select
          id="pagesa"
          onChange={handlePagesaChange}
          onBlur={handlePagesaBlur}
          value={pagesaMenyra}
        >
          {pagesaOptions.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
      <div>
        <label htmlFor="dergimi">{t("deliverymethod")}:</label>
        <select
          id="dergimi"
          onChange={handleDergimiChange}
          onBlur={handleDergimiBlur}
          value={dergesaMenyra}
        >
          {dergimiOptions.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
      <div ref={pdfRef}>
        {/* Include your CartPdf component here */}
        <CartPdf />
      </div>
{/*
      <Button variant="contained" size="medium" onClick={generatePDF}>
        {t("generatepdf")}
      </Button>
        */}
      <ConfirmationDialog open={isDialogOpen} onClose={handleCancel} onConfirm={handleConfirm} />
    </div>
  );
};

export default GeneratePDF;
