import React from "react";
import "./Cart.css";
import { useCartContext } from "../../contexts/CartContext";
import { useTranslation } from "react-i18next";
import PopupTrigger from "../checkout/PopupTrigger";
import { Tooltip } from "@mui/material";

const Cart = () => {
	const {
		setCartItems,
		cartItems,
		handleIncrementQuantity,
		handleDecrementQuantity,
		totalShuma,
		handleAddManualQuantity,
		handleRemoveItem,
	} = useCartContext();

	function removecart() {
		setCartItems([]);
		localStorage.removeItem("updatedcartstorage");
	}

	const { t } = useTranslation();

	return (
		<div className="rightcart">
			<div className="cart_titulli">{t("orders")}</div>
			<div className="cart-container">
				<table className="tabela_cart">
					<tbody className="table-body">
						{cartItems.map((item, index) => (
							<tr className="cart-table-row" key={index}>
								<td className="cartfirstsection">
									<div className="cart-column cart-column-info">
										<div className="cart-produktname">
											{t("name")}: {item.produkt_name} ({item.brand})
										</div>
										<div className="cart-oemnr">
											Oem Nr.: {item.oem_nr}, Vend Nr.: {item.vend_nr}
										</div>
									</div>
									<button
										className="buttonRemove"
										onClick={() => handleRemoveItem(index)}
									>
										X
									</button>
								</td>
								<td id="buttons-container1" className="bordertop">
									<div className="cmimishumice">
										{t("dealerprice")}: {item.cmimi_shumice}
									</div>
									<div className="buttonplusminus">
										<button onClick={() => handleDecrementQuantity(index)}>
											-
										</button>
										<input
											type="text"
											className="quantity"
											value={item.quantity}
											onChange={(e) =>
												handleAddManualQuantity(index, e.target.value)
											}
										/>
										<button onClick={() => handleIncrementQuantity(index)}>
											+
										</button>
									</div>
									<p className="totalshuma">
										{t("pricesum")}: {item.shuma}
									</p>
								</td>
							</tr>
						))}
					</tbody>
				</table>
				<div className="totali">
					<div>
						{t("totalsuma")}: {totalShuma}
					</div>
				</div>
				<div className="checkoutbutton">
					<PopupTrigger />
				</div>
				<div className="anuloporosine">
					{/*<Tooltip title={t("tooltiptext")} arrow>
					<button
						className="anuloporosinebtn"
						onClick={removecart}
						data-tip={t("tooltiptext")}
						data-for="cancelOrderTooltip"
					>
						{t("cancelorder")}!
					</button>
					</Tooltip>*/}
				</div>
			</div>
		</div>
	);
};

export default Cart;
