import React, { useEffect } from 'react';

export default function CheckAndRedirectQuirksMode() {
  const baseserver = process.env.REACT_APP_APP_NAME === 'benfiko'
    ? 'http://localhost:3000'
    : 'https://185.226.0.16:3020';

  useEffect(() => {
    if (document.compatMode !== 'CSS1Compat') {
      // Redirect to the home page
      window.location.href = baseserver;
    }
  }, [baseserver]);

  return null;
}
