import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./contexts/AuthContext";
import { CartProvider } from "./contexts/CartContext";
// eslint-disable-next-line
import i18n from "./components/translations/i18n";
import { I18nextProvider } from "react-i18next";
import LoginCheck from "./components/login/LoginCheck";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<I18nextProvider i118n={i18n}>
			<AuthProvider>
				<CartProvider>
					<LoginCheck />
				</CartProvider>
			</AuthProvider>
		</I18nextProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
