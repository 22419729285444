import React from "react";
import "./Styles.css";
import Cart from "../cart/Cart";
import { useCartContext } from "../../contexts/CartContext";
import { useAuthContext } from "../../contexts/AuthContext";
import imagePaths from "../imagepaths";


const RighttColumn = () => {
  //const carModelsImage=process.env.PUBLIC_URL+'/img/carmodels/'
  const { loggedIn } = useAuthContext();
  const { cartItems } = useCartContext();
  // const [parsedItems, setParseItems] = useState([]);
  const images = process.env.REACT_APP_APP_NAME === 'benfiko'
  ? [
    {name:"audi", path:imagePaths.audiimage},
    {name:'vw', path:imagePaths.vwimage},
    {name:'bmw', path:imagePaths.bmwimage},
    {name:'mercedes', path:imagePaths.mercedesimage},
    {name:'seat', path:imagePaths.seatimage},
    {name:'skoda', path:imagePaths.skodaimage},
    {name:'alfaromeo', path:imagePaths.alfaromeoimage},
    {name:'fiat', path:imagePaths.fiatimage},
    {name:'landrover', path:imagePaths.landrover},
    {name:'mitsubishi', path:imagePaths.mitsubishi},
    {name:'suzuki', path:imagePaths.suzuki},
    {name:'mazda', path:imagePaths.mazda},
    {name:'toyota', path:imagePaths.toyota},
    {name:'honda', path:imagePaths.honda},
    {name:'nissan', path:imagePaths.nissan}
  
  ]
  :   [ 
  {name:'opel',path:imagePaths.opel},
  {name:'chevrolet',path:imagePaths.chevrolet},
  {name:'peugeot',path:imagePaths.peugeot},
  {name:'renault',path:imagePaths.renault},
  {name:'ford',path:imagePaths.ford},
  {name:'citroen',path:imagePaths.citroen},
  {name:'dacia',path:imagePaths.dacia}
]

  /*
  const getImages = () => {
    const imagePaths = [];
    const imageFiles = require.context(
      carModelsImage,
      false,
      /\.png$/
    );
    imageFiles.keys().forEach((item) => {
      const imageName = item
        .split("/")
        .pop()
        .replace(/\.\w+$/, "");
      imagePaths.push({
        name: imageName,
      });
    });
    return imagePaths;
  };
*/
  //const images = getImages();

  const renderImageColumns = () => {
    const columns = [[], []];

    images.forEach((image, index) => {
      columns[index % 1].push(image);
    });

    return columns;
  };

  const imageColumns = renderImageColumns();

  return (
    <div>
      {cartItems.length && loggedIn > 0 ? (
        <Cart />
      ) : (
        <div className="car_logos">
          <h1 className="brandstitle"> </h1>
          <div style={{ display: "flex" }} >
            <div style={{ flex: 1, padding: "10px", width:'350px' }} className="carmodelkolona">
              {imageColumns[0].map((image, index) => (
                <div key={index} className="image-item">
                  <img
                    src={image.path}
                    alt={image.name}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RighttColumn;
