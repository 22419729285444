import React, { useState, useCallback } from "react";
import {
	TextField,
	InputAdornment,
	Radio,
	RadioGroup,
	FormControlLabel,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useCartContext } from "../../contexts/CartContext";
import "./SearchBarNew.css";
//import { useTranslation } from "react-i18next";
import { t } from "i18next";
//const { t } = useTranslation();

const SearchBar = () => {
	const {
		query,
		setQuery,
		handleSearchQuery,
		handleSearchNameQuery,
		setResults,
	} = useCartContext();
	const [searchType, setSearchType] = useState("mytecdoc");

	const handleSearchClick = useCallback(() => {
		if (query.trim() === "") {
			setResults([]);
			console.log("Query is empty, search not initiated.");
			return;
		}
		if (searchType === "mytecdoc") {
			handleSearchQuery(query);
		} else {
			handleSearchNameQuery(query);
		}
	}, [handleSearchQuery, handleSearchNameQuery, query, searchType]);

	const handleInputChange = useCallback(
		(e) => {
			const inputText = e.target.value;
			let sanitizedQuery;

		

			if (searchType === "mytecdoc") {
				sanitizedQuery = inputText.replace(/[^A-Za-z0-9\/.-]/gi, ""); //sanitizedQuery = inputText.replace(/[^A-Za-z0-9]/gi, ""); // Disallow spaces
			} else {
				sanitizedQuery = inputText.replace(/[^A-Za-z0-9\s/.-]/gi, ""); // Allow spaces "/" and "."// sanitizedQuery = inputText.replace(/[^A-Za-z0-9\s]/gi, ""); // Allow spaces
			}
           // let encodedQuery = encodeURIComponent(sanitizedQuery);
			setQuery(sanitizedQuery);
		},
		[setQuery, searchType]
	);

	const handleEnterKeyPress = (e) => {
		if (e.key === "Enter") {
			handleSearchClick();
		}
	};

	const handleSearchTypeChange = (e) => {
		setSearchType(e.target.value);
		//setQuery("");
	};

	const currentRoute = window.location.pathname;
	if (currentRoute !== "/") {
		return null;
	}

	const handlePaste = (e) => {
		e.preventDefault();
		const pastedText = e.clipboardData.getData("text/plain");
		let sanitizedQuery;
		if (searchType === "mytecdoc") {
			sanitizedQuery = pastedText.replace(/[^A-Za-z0-9\/.-]/gi, ""); // Disallow spaces
		} else {
			sanitizedQuery = pastedText.replace(/[^A-Za-z0-9\s/.-]/gi, ""); // Allow spaces
		}
       // let encodedQuery = encodeURIComponent(sanitizedQuery);
		setQuery(sanitizedQuery);
	};

	return (
		<div className="search-container">
			<div className="searchbar">
				<TextField
					className="search-input-navbar"
					id="search"
					type="search"
					//label={t("searchbarlabel")}
					size="small"
					value={query}
					onChange={handleInputChange}
					onKeyPress={handleEnterKeyPress} // Handle Enter key press
					onPaste={handlePaste}
					placeholder={`${t("searchby")} ${
						searchType === "mytecdoc"
							? `${t("oemnumber")}`
							: `${t("productname")}`
					}...`}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<RadioGroup
									row
									value={searchType}
									onChange={handleSearchTypeChange}
								>
									<FormControlLabel
										value="mytecdoc"
										control={<Radio />}
										label={t("oemnumber")}
									/>
									<FormControlLabel
										value="products"
										control={<Radio />}
										label={t("productname")}
									/>
								</RadioGroup>
								<SearchIcon
									cursor="pointer"
									onClick={handleSearchClick}
									sx={{ "&:hover": { color: "blue" } }}
									placeholder={`Search by ${
										searchType === "mytecdoc"
											? `${t("oemnumber")}`
											: `${t("productname")}`
									}...`}
								/>
								<div></div>
							</InputAdornment>
						),
					}}
				/>
			</div>
		</div>
	);
};

export default SearchBar;
