import React, { useEffect, useState } from "react";
import imagePaths from "../imagepaths";
import axios from "axios";
import { chatUrl } from "../../services/ApiService";

function CheckNewMessages({ onClick }) {
	const [userId] = useState(+localStorage.getItem("customerid"));
	const [clientMessage, setClientMessage] = useState(		JSON.parse(localStorage.getItem("clientmessages")) || {}	);
	const [companyMessage, setCompanyMessage] = useState(
		JSON.parse(localStorage.getItem("companymessages")) || {}
	);
	const [changedChatIcon, setChangedChatIcon] = useState(imagePaths.chaticon);
	const [lastmessage, setLastMessage] =useState(JSON.parse(localStorage.getItem("companymessages")) || {})

	const options = {
		year: "numeric",
		month: "numeric",
		day: "numeric",
	};

	const timeoptions = {
		timeZone: "Europe/Paris",
		hour: "2-digit",
		minute: "2-digit",
		second: "2-digit",
		hour12: false,
	};
	const [isNewMessage, setIsNewMessage] = useState("chaticon");

	function messagesAreIdentical(msg1, msg2) {
		return JSON.stringify(msg1) === JSON.stringify(msg2);
	}

	function logDifferences(msg1, msg2) {
		const keys = new Set([...Object.keys(msg1), ...Object.keys(msg2)]);
		keys.forEach((key) => {
			if (msg1[key] !== msg2[key]) {
				console.log(
					`Difference in key "${key}": clientMessage = ${msg1[key]}, newMessageData = ${msg2[key]}`
				);
			}
		});
	}

	const handleClick = () => {
		// Sync clientMessage with companyMessage
		setClientMessage(companyMessage);
		localStorage.setItem("clientmessages", JSON.stringify(companyMessage));
		localStorage.setItem("lastmessage", JSON.stringify(companyMessage));

		// Reset chat icon
		setChangedChatIcon(imagePaths.chaticon);
		setIsNewMessage("chaticon");

		// Execute the passed onClick function
		onClick();
	};

	useEffect(() => {
		const checkNewMessages = async () => {
			const formattedDate = new Date().toLocaleDateString("de-DE", options);

			try {
				const response = await axios.get(`${chatUrl}/${userId}/1/${formattedDate}`);
				if (response.data && response.data.length > 0) {
					const latestMessage = response.data[response.data.length - 1];
					const newMessageData = {
						id_customer: latestMessage.id_customer,
						id_employee: latestMessage.id_employee,
						text: latestMessage.text,
						data: new Date(latestMessage.data).toLocaleDateString(
							"de-DE",
							options
						),
						ora: new Date(latestMessage.ora).toLocaleTimeString(
							"en-US",
							timeoptions
						),
						sent_by_customer: latestMessage.sent_by_customer,
						sent_by_employee: latestMessage.sent_by_employee,
					};
					const storedClientMessages = JSON.parse(localStorage.getItem("clientmessages"));
					//localStorage.setItem("lastmessage", JSON.stringify(newMessageData));
					if (messagesAreIdentical(storedClientMessages, newMessageData)) {
						console.log("No new messages compared");
						setChangedChatIcon(imagePaths.chaticon);
						setIsNewMessage("chaticon");

					} else {
						console.log("New message or modifications detected:");
						logDifferences(storedClientMessages, newMessageData);
						setCompanyMessage(newMessageData);
						localStorage.setItem("companymessages", JSON.stringify(newMessageData));
						setChangedChatIcon(imagePaths.chaticon);
						setIsNewMessage("chaticon danger");
					}
				}
			} catch (error) {
				console.error("Error:", error);
			}
		};

		checkNewMessages();
		const intervalId = setInterval(checkNewMessages, 30000); // Fetch messages every 30 seconds
		return () => clearInterval(intervalId); // Cleanup interval on unmount
	}, [userId]);

	return (
		<img
			src={changedChatIcon}
			alt={companyMessage.text ? "New Messages" : "No New Messages"}
			className={`chaticon ${isNewMessage}`} // Apply the glowing effect if there's a new message
			onClick={handleClick}
		/>
	);
}

export default CheckNewMessages;
