// BackToTop.js

import React, { useState, useEffect } from "react";
//import "./BackToTopPyramid.css";

import imagePaths from "../imagepaths";
// eslint-disable-next-line
const appStyles = process.env.REACT_APP_APP_NAME === 'benfiko' ? require('./benfikoBackToTopPyramid.css') : require('./autostarBackToTopPyramid.css');

const BackToTopPyramid = () => {
	const [isVisible, setIsVisible] = useState(false);

	useEffect(() => {
		const handleScroll = () => {
			const scrollY = window.scrollY;
			setIsVisible(scrollY > 300);
		};

		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	const scrollToTop = () => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	};

	return (
		<div
			className={`back-to-top ${isVisible ? "visible" : ""}`}
			onClick={scrollToTop}
		>

            <img className="imagetopleft"
                src={imagePaths.doublearrowupp}
				alt="gototop"
                ></img>
   

		</div>
	);
};

export default BackToTopPyramid;
