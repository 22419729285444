import React, { useEffect, useState } from "react";
import "./css/contact.css";
import imagePaths from "../../src/components/imagepaths";
import { t } from "i18next";

function Contact() {
	const images =
		process.env.REACT_APP_APP_NAME === "benfiko"
			? [
					{ name: "audi", path: imagePaths.audiimage },
					{ name: "vw", path: imagePaths.vwimage },
					{ name: "bmw", path: imagePaths.bmwimage },
					{ name: "mercedes", path: imagePaths.mercedesimage },
					{ name: "seat", path: imagePaths.seatimage },
					{ name: "skoda", path: imagePaths.skodaimage },
					{ name: "alfaromeo", path: imagePaths.alfaromeoimage },
					{ name: "fiat", path: imagePaths.fiatimage },
			  ]
			: [
					{ name: "opel", path: imagePaths.opel },
					{ name: "chevrolet", path: imagePaths.chevrolet },
					{ name: "peugeot", path: imagePaths.peugeot },
					{ name: "renault", path: imagePaths.renault },
					{ name: "ford", path: imagePaths.ford },
					{ name: "citroen", path: imagePaths.citroen },
					{ name: "dacia", path: imagePaths.dacia },
			  ];
	const caontactinfojson =
		process.env.REACT_APP_APP_NAME === "benfiko"
			? "./contact/contactbenfiko.json"
			: "./contact/contactautostar.json";
	const street =
		process.env.REACT_APP_APP_NAME === "benfiko"
			? "adressbenfiko"
			: "adressautostar";

	const contactslash = imagePaths.contactslash;
	const renderImageColumns = () => {
		const columns = [[], []];

		images.forEach((image, index) => {
			columns[index % 1].push(image);
		});

		return columns;
	};
	//----------------------get from autostar.com.mk only contact with id='content'
	const [contactInfo, setContactInfo] = useState("./contact.json");

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await fetch(caontactinfojson); // Replace "/path/to/contactInfo.json" with the actual path to your JSON file
				const data = await response.json();
				setContactInfo(data);
			} catch (error) {
				console.error("Error fetching contact info:", error);
			}
		};

		fetchData();
		// console.log(contactInfo)
	}, []);

	const imageColumns = renderImageColumns();
	return (
		<>
			<div className="adresapaorar">
				<div className="nakontaktoni">{t("contactus")}</div>
				<div className="separatorcontactus"></div>
				<div className="pershkrimiimages">
					<div className="pershkrimmibuttonat">
						<div className="pershkrimikontakt">{t("description")}</div>

						{/*<img src={imagePaths.contactusbutton} alt="contactusbutton" className="buttonatekontaktit"></img>
						<img src={imagePaths.homebutton} alt="homebutton" className="buttonatekontaktit"></img>*/}
					</div>
					<div className="imagecarbrandcontct">
						<img
							src={contactInfo.rightimagepath}
							className="contactslash"
							alt="contactslash"
						></img>
						<div className="imagetebrendeve">
							{imageColumns[0].map((image, index) => (
								<div key={index} className="imageitemcontact">
									<img src={image.path} alt={image.name} />
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
			<div className="adresaorari">
				<div>
					<div className="adresstitlecontact" id="titujtcontactnalt">
						{t("ouradress")}
					</div>
					<div>
						<div className="adresatitleadres">
							<div className="adresatitle" id="titujtcontact">
								{t("adress")}
							</div>
							{t(street)}

						</div>
						<div className="adresatitleadres">
							<div id="titujtcontact">{t("phonenr")}</div>
							{contactInfo.phone}
						</div>
						<div className="adresatitleadres">
							<div id="titujtcontact">{t("email")}:</div> {contactInfo.email}
						</div>
					</div>
				</div>
				<div>
					<div className="oraripunestitlecontact" id="titujtcontactnalt">
						{t("oraripunes")}
					</div>
					<div>
						<div>{t("mondayfriday")} {contactInfo.MondayFriday}</div>
						<div>{t("saturday")} {contactInfo.Saturday}</div>
						<div>{t("sunday")} {t("closed")}</div>
					</div>
				</div>
			</div>
		</>
	);
}
export default Contact;
