import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Promo.css";
import { kampanjaURL } from "../../services/ApiService";

const PromoHome = () => {
    const [results, setResults] = useState([]);

    useEffect(() => {
        async function getPromoOnly() {
            try {
                const response = await axios.get(kampanjaURL);
                if (response.data.length === 0) {
                    setResults([]);
                } else {
                    const transformedResults = response.data.map((item) => ({
                        id: item.id,
                        foto: item.foto_kampanja,
                    }));
                    setResults(transformedResults);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
                setResults([]);
            }
        }
        getPromoOnly();
    }, []);

    return (
        <div className="columnpromo">
            <div className={`imageitempromo ${results.length > 1 ? "morethanone" : ""}`}>
                {results.map((item, index) => (
                    <img key={index} src={`data:image/jpeg;base64,${item.foto}`} alt={`Imagepromo ${index}`} />
                ))}
            </div>
        </div>
    );
};

export default PromoHome;
