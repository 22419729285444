import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useState } from "react";
import { useAuthContext } from "../contexts/AuthContext";
import { useTranslation } from "react-i18next";
import LanguageToggle from "../components/translations/LanguageToggle";
//import logoImage from "../img/logo1.png";
import axios from "axios";
import { firmaURL, loginURL } from "../services/ApiService";
import { useCartContext } from "../contexts/CartContext";
import { encode } from "base-64";
import SignOutInactiveUser from "../components/inactivitycheck/checkinactivity";

//import imagePaths from "../components/imagepaths";
const logoPath =
	process.env.REACT_APP_APP_NAME === "benfiko"
		? "/img/logo/benfikoLogoSignIn.png"
		: "/img/logo/autostarLogoBlack.png";

function Copyright(props) {
	return (
		<Typography
			variant="body2"
			color="text.secondary"
			align="center"
			{...props}
		>
			{"Copyright © "}
			<Link color="inherit" href="https://tetnet-pro.com/">
				Tetnet Pro Computers
			</Link>{" "}
			{new Date().getFullYear()}
			{"."}
		</Typography>
	);
}
const defaultTheme = createTheme();
export default function SignIn() {
	const { Login, setUserId /*, userId*/ } = useAuthContext();
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	//const { /*setCartItems, setTotalShuma,*/ cartItems /*, setHistoryOrder*/ } =
	useCartContext();
	//const token = localStorage.getItem("token");
	const { t } = useTranslation();


	const HandleUsernameChange = (event) => {
		setUsername(event.target.value);
	};

	const HandlePasswordChange = (event) => {
		setPassword(event.target.value);
	};

	const HandleLogin = async (event) => {
		// Pass the event as a parameter
		event.preventDefault(); // Prevent default form submission

		try {
			const encrypdetusername = encode(username);
			const encryptedpassword = encode(password);

			const postData = {
				username: encrypdetusername,
				pass: encryptedpassword, // Using "pass" for the password field
			};
			const response = await axios.post(loginURL, postData);

			if (response.data.token) {
				const data = response.data;
				const token = data.token;
				const ipAdresa = data.ipAddress;
				localStorage.setItem("token", token);
				localStorage.setItem("IpAdresa", ipAdresa ?? "127.0.0.1");
				const checktoken = localStorage.getItem("checktoken") || "";
				if (!checktoken) {
					localStorage.setItem("checktoken", token);
				}
				setUserId(data.user.id);
				localStorage.setItem("customerid", data.user.id);
				localStorage.setItem("companyname", data.user.company);

				//---------------------------------------------------------

				//const id = data.user.id;

				//const urlWithId = initialCartURL + data.user.id + "/0";

				//const urlOrderHistory = historyOrderURL + "-1/" + data.user.id + "/1";
				//localStorage.setItem(	"historyorderstorage")||[];
				await axios.get(firmaURL).then(function (response){
					const firmaEmri=response.data;
					console.log(firmaEmri[0].emri)
					localStorage.setItem("firmaemri", firmaEmri[0].emri)
				})
				
				//-----------------------historiku i ordersdetails---------------
				/*	const urlHistory = initialCartURL + data.user.id + "/1";
				await axios
					.get(urlHistory)
					.then(function (response) {
						const responseWithShumaHistory = response.data.map((it) => {
							return {
								shuma: it.total,
								cmimi_shumice: it.last_price,
								oem_nr: it.upcsku,
								vend_nr: it.vendoritemnumber,
								produkt_name: it.productname,
							};
						});
						localStorage.setItem(
							"historycartstorage",
							JSON.stringify(responseWithShumaHistory)
						);
						//setCartItems(JSON.parse(localStorage.getItem("updatedcartstorage")));
						console.log("cartitems ", cartItems);
					})
					.catch(function (error) {
						// handle error
						console.log(error);
					})
					.finally(function () {
						// always executed
					});
					*/
				//------------------------------------------------
				//-----------------------historiku i orders---------------
				/*
				await axios
					.get(urlOrderHistory)
					.then(function (response) {
						const responseWithShumaHistoryOrder = response.data.map((it) => {
							//const dateWithoutTime = new Date(it.datetimesubmited).toLocaleDateString();
							return {
							
								datetimesubmited: it.datetimesubmited,
								menyra_pagesa: it.menyra_pagesa,
								menyra_dorezimi: it.menyra_dorezimi,
								shuma: it.shuma,
							};
						});
						localStorage.setItem(
							"historyorderstorage",
							JSON.stringify(responseWithShumaHistoryOrder)
						);
						//setCartItems(JSON.parse(localStorage.getItem("updatedcartstorage")));
						//console.log("historyorders ", responseWithShumaHistoryOrder);
					})
					.catch(function (error) {
						// handle error
						console.log(error);
					})
					.finally(function () {
						// always executed
					});
					*/

				//------------------------------------------------
				Login();
			} else {
				alert("Your username or password did not match.");
			}
		} catch (error) {
			//console.error("Error during login:", error);
			alert("An error occurred while logging in.");
		}
	};
	// eslint-disable-next-line 
	//const initialCartItems =JSON.parse(localStorage.getItem("updatedcartstorage")) || [];
	//const [cartItems, setCartItems] = useState(initialCartItems);
	//setCartItems(initialCartItems)
	// eslint-disable-next-line 
	{/* 
	function getRemainingLocalStorageSpaceInMB() {
		const maxLocalStorageSizeInBytes = 5 * 1024 * 1024; // 5 megabytes
		const usedLocalStorageSpaceInBytes = unescape(
			encodeURIComponent(JSON.stringify(localStorage))
		).length;
		const remainingSpaceInBytes =
			maxLocalStorageSizeInBytes - usedLocalStorageSpaceInBytes;
		const remainingSpaceInMB = remainingSpaceInBytes / (1024 * 1024);
		return remainingSpaceInMB.toFixed(2); // Return with two decimal places
	}
*/}
	//const remainingSpaceInMB = getRemainingLocalStorageSpaceInMB();
	//console.log("Remaining localStorage space:", remainingSpaceInMB, "MB");

	return (
		<ThemeProvider theme={defaultTheme}>
			<Container component="main" maxWidth="xs">
	
				<LanguageToggle />
				<CssBaseline />
				<Box
					sx={{
						marginTop: 8,
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}
				>
					<img src={process.env.PUBLIC_URL + logoPath} alt="Logo" />
					<Typography component="h1" variant="h5">
						{t("signin")}
					</Typography>
					<Box
						component="form"
						onSubmit={HandleLogin}
						noValidate
						sx={{ mt: 1 }}
					>
						<TextField
							margin="normal"
							required
							fullWidth
							id="usernamе"
							label={t("username")}
							name="username"
							autoComplete="username"
							autoFocus
							value={username}
							onChange={HandleUsernameChange}
						/>
						<TextField
							margin="normal"
							required
							fullWidth
							name="password"
							label={t("password")}
							type="password"
							id="password"
							autoComplete="current-password"
							value={password}
							onChange={HandlePasswordChange}
						/>
						<FormControlLabel
							control={<Checkbox value="remember" color="primary" />}
							label={t("rememberme")}
						/>
						<Button
							type="submit"
							fullWidth
							variant="contained"
							sx={{ mt: 3, mb: 2 }}
							onClick={HandleLogin}
						>
							{t("signin")}
						</Button>
						{/*}
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
      
            </Grid>
              */}
					</Box>
				</Box>
				<Copyright sx={{ mt: 8, mb: 4 }} />
			</Container>
		</ThemeProvider>
	);
}
