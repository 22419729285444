// CartPdf.js

import React from "react";
import { useTranslation } from "react-i18next";
import { useCartContext } from "../../contexts/CartContext";
import "./CartPdf.css";
import imagePaths from "../imagepaths";
const logoPath =
	process.env.REACT_APP_APP_NAME === "benfiko"
		? "/img/logo/benfikoLogoSignIn.png"
		: "/img/logo/autostarLogoBlack.png";


const CartPdf = () => {
  const { cartItems, totalShuma } = useCartContext();
  const { t } = useTranslation();

  // Get the current date
  const currentDate = new Date().toLocaleDateString();
  const companyname=localStorage.getItem("companyname");

  return (
    <div className="invoice-container">
      <div className="invoice-header">
        <img src={logoPath} alt="Company Logo" className="logo" />
        <div className="company-info">
          <div>{t("shipto")}: </div>
          {companyname}
        </div>
      </div>

      <div className="invoice-title">{t("orders")}</div>

      <div className="cart-container-pdf">
        <table className="cart-table">
          <thead>
            <tr>
              <th>{t("produktname")}</th>
              <th>{t("oemnr")}</th>
              <th>{t("vendnr")}</th>
              <th>{t("price")}</th>
              <th>{t("quantity")}</th>
              <th>{t("pricesum")}</th>
            </tr>
          </thead>

          <tbody>
            {cartItems.map((item, index) => (
              <tr key={index}>
                <td>{item.produkt_name}</td>
                <td>{item.oem_nr}</td>
                <td>{item.vend_nr}</td>
                <td>{item.cmimi_shumice}</td>
                <td>{item.quantity}</td>
                <td>{item.shuma}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="invoice-footer">
        <div className="additional-info">
          <div>{t("shippingInfo")}:</div>
          {/* Add shipping information here */}
        </div>
        <div className="total-info">
          {t("totalsuma")} {totalShuma}
        </div>
      </div>

      <div className="invoice-footer">
        <div className="additional-info">
          <div>{t("date")}:</div>
          <div>{currentDate}</div>
        </div>
      </div>
    </div>
  );
};

export default CartPdf;
