// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enTranslation from './locales/en.json'; // Adjust the path accordingly
import mkTranslation from './locales/mk.json'; // Adjust the path accordingly
import alTranslation from './locales/al.json'; // Adjust the path accordingly
//import trTranslation from './locales/tr.json'; // Adjust the path accordingly
//import deTranslation from './locales/de.json'; // Adjust the path accordingly
//import frTranslation from './locales/fr.json'; // Adjust the path accordingly


const resources = {
  en: {
    translation: enTranslation,
  },
  mk: {
    translation: mkTranslation,
  },
  al: {
    translation: alTranslation,
  },
  //tr: {    translation: trTranslation,  },
  //de: {    translation: deTranslation,  },
  //fr: {    translation: frTranslation,  },
  // Add more languages as needed
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'al', // Set the default language
    interpolation: {
      escapeValue: false, // React already escapes by default
    },
  });

export default i18n;
