import React from "react";
import "./css/HomeLayout.css";
 //import Results from "../Results";
import RighttColumn from "../components/rightcolumn/RightColumn";
import Rezultatet from '../components/results/Rezultatet'
import SearchBar from "../components/searchbar/Searchbar";
import PromoLeft from "../components/leftcolumn/PromoLeft";
import Brands from "../components/leftcolumn/brands";
import Brend1 from "../components/leftcolumn/brend1";
//import Footer from "../components/footer/Footer";

//import ResultsNew from "../ResultsNew";

const Home = () => {


	//const { handleAddToCartResult} = useCartContext();
	//const handleAddToCart = () => handleAddToCartResult();

	return (
		<><SearchBar/>
		<div className="grid-container-home">
			<div className="leftcolumn"> 
			<Brands/>
			{/*<Brend1/>*/}
			</div>
			<div className="centercolumn">
				<Rezultatet />
				<div className="qender"> </div>
				{/* <Results results={results} handleAddToCart={handleAddToCart} /> */}
			</div>
			<div className="rightcolumn">
				<RighttColumn />
			</div>
	
			
	
			
		</div>
		</>
	);
};

export default Home;
