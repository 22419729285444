// Import necessary dependencies
import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Home from "../../pages/Home";
import Orders from "../../pages/Orders";
import History from "../../pages/History";
//import "./autostarNavigation.css";
//import "./benfikoNavigation.css";

import SignIn from "../../pages/SignIn";
//import "./navi1.css"
import { useTranslation } from "react-i18next";
import ChatModule from "../../components/chat/ChatModule";
import Contact from "../../pages/Contact";
import CheckAndRedirectQuirksMode from "../../components/errornavigation/checkAndRedirectQuirksMode";

const appStyles =
	process.env.REACT_APP_APP_NAME === "benfiko"
		? require("./benfikoNavigation.css")
		: require("./autostarNavigation.css");

const urlpublice = process.env.PUBLIC_URL;

const logoPath =
	process.env.REACT_APP_APP_NAME === "benfiko"
		? "/img/logo/benfikoLogo.png"
		: "/img/logo/autostarLogo.png";

// Create the Navigation component
const Navigation = () => {
	const { t } = useTranslation();

	return (
		<Router>
			<CheckAndRedirectQuirksMode />
			<nav className="container">
				<ul className="content">
					<img src={process.env.PUBLIC_URL + logoPath} alt="logo" />
					<div className="menu">
						<li>
							<Link to="/">{t("home")}</Link>
						</li>

						<li>
							<Link to="/history">{t("history")}</Link>
						</li>
						<li>
							<Link to="/contact">{t("contact")}</Link>
						</li>
						{/* Uncomment the below if needed */}
						{/* <li>
				<Link to="/signin">{t("signin")}</Link>
			  </li> */}
					</div>
				</ul>
			</nav>

			<Routes>
				<Route path="/" element={<Home />} />

				<Route path="/history" element={<History />} />
				<Route path="/contact" element={<Contact />} />
				{/* Uncomment the below if needed */}
				{/* <Route path="/signin" element={<SignIn />} /> */}
			</Routes>
		</Router>
	);
};

export default Navigation;
