// PopupTrigger.js
import React, { useState } from "react";
//import { Button } from "@mui/material";
import CheckoutForm from "./CheckoutForm";
import "./CheckoutForm.css";
//import inventoryData from "./db/inventory.json";
import { useTranslation } from "react-i18next";
//import { useCartContext } from "../../contexts/CartContext";
//import getInventoryDataById from "./CheckInventory";


const PopupTrigger = () => {
	const { t } = useTranslation(); // Destructure the t function
	const [open, setOpen] = useState(false);
	//const { setCartItems, setCartItemsRemoved } = useCartContext();
	//const [inventory, setInventory] = useState();
	const handleOpen = () => {
	
		// Get the products from localStorage
		//const storedCart =JSON.parse(localStorage.getItem("updatedcartstorage")) || [];
		//const storedcardget =			JSON.parse(localStorage.getItem("updatedcartstorage")) || [];
			/*
			const getinventbyid = Promise.all(
				storedcardget.map((cartItem) => getInventoryDataById(cartItem.id))
			  )
				.then((storedCart) => {
				  let removedItems = [];
				  for (let i = 0; i < storedcardget.length; i++) {
					if (inventory[i] && inventory[i].quantity > 0) {
					  removedItems.push(inventory[i]);
					}
					return storedCart
				  }
				  
				  // Store the resolved inventory data in localStorage
				  localStorage.setItem("getinventdatabyid", JSON.stringify(removedItems));
				  
				  // Display removed items
				  console.log("Removed Items:", removedItems);
			  
				  // You can update your UI or handle removed items as needed here
				})
				.catch((error) => {
				  // Handle any errors that may occur during the requests.
				});
				*/
				//localStorage.setItem("getinventdatabyidJasht", JSON.stringify(getinventbyid));
		//console.log("getinventdatabyid", getinventbyid)

		// Filter out items with a quantity of 0 in the JSON file and save removed items
		/*
		const removedItems = storedCart.filter((storedProduct) => {
			const matchingProduct = inventoryData.RecordSet.find(
				(product) => product.ID === storedProduct.id
			);
			const shouldRemove = matchingProduct && matchingProduct.QUANTITY <= 0;
			return shouldRemove;
		});
*/
/*
		function updateCartWithInventory(storedCart, inventoryData) {
			const removedItems = [];

			const updatedCart = storedCart
				.map((storedProduct) => {
					const matchingProduct = inventoryData.RecordSet.find(
						(product) => product.ID === storedProduct.id
					);

					if (matchingProduct && matchingProduct.QUANTITY > 0) {
						const updatedQuantity = Math.min(
							storedProduct.quantity,
							matchingProduct.QUANTITY
						);

						if (updatedQuantity !== storedProduct.quantity) {
							// Calculate removed quantity
							const removedQuantity = storedProduct.quantity - updatedQuantity;

							// Save the removed item in the removedItems array
							removedItems.push({
								...storedProduct,
								quantity: removedQuantity,
							});
						}

						return {
							...storedProduct,
							quantity: updatedQuantity,
						};
					}

					return null; // Return null for items to be filtered out.
				})
				.filter((item) => item !== null);

			return { updatedCart, removedItems };
		}

		const removedItems_old =
			JSON.parse(localStorage.getItem("removeditems")) || [];

		const updatedCart = updateCartWithInventory(storedCart, inventoryData);
		const removedItemsupdated = [
			...removedItems_old,
			...updatedCart.removedItems,
			...removedItems,
		];
		localStorage.setItem(
			"removeditemsUpdated",
			JSON.stringify(removedItemsupdated)
		);
		localStorage.setItem(
			"removeditems",
			JSON.stringify(updatedCart.removedItems)
		);
		localStorage.setItem(
			"Removeditems out of stock=0",
			JSON.stringify(removedItems)
		);

		localStorage.setItem(
			"updatedcartstorage",
			JSON.stringify(updatedCart.updatedCart)
		);
		setCartItems(JSON.parse(localStorage.getItem("updatedcartstorage")) || []);
		//setCartItemsRemoved( JSON.parse(localStorage.getItem("removeditemsUpdated")) || []);
*/
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
//setCartItems([])
		//clearCartStorage();
		//localStorage.removeItem("updatedcartstorage");
	};

	return (
		<div className="checkoutform" id="buttonscontainer">
	
			<button /*variant="contained" color="primary"*/ onClick={handleOpen}>
				{t("opencheckout")}
			</button>
			<CheckoutForm open={open} handleClose={handleClose} />
		</div>
	);
};

export default PopupTrigger;
