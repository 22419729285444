import { useEffect, useState } from "react";
import BackToTopPyramid from "./components/backtotop/BacktToTopPyramid";
import Navigation from "./header/navigation/Navigation";
import TopHeader from "./header/topheader/topheader";
import CheckNewMessages from "./components/chat/checkNewMessages";
import SignOutInactiveUser from "./components/inactivitycheck/checkinactivity";

const appStyles =
  process.env.REACT_APP_APP_NAME === "benfiko"
    ? require("./benfikoApp.css")
    : process.env.REACT_APP_APP_NAME === "autostar"
    ? require("./autostarApp.css")
    : null;

function App() {
  const defaultTitle = "Autoparts B2B";
  const [appName, setAppName] = useState(defaultTitle);
  const [faviconNew, setFavIconNew] = useState('favicon-default.ico');
  localStorage.setItem("clientmessages",JSON.stringify({}));
  localStorage.setItem("companymessages",JSON.stringify({}));

  useEffect(() => {
    const storedAppName = localStorage.getItem('firmaemri');
    if (storedAppName) {
      setAppName(`${storedAppName} B2B`);
      setFavIconNew(`favicon-${storedAppName.toLowerCase()}.ico`);
    } else {
      setAppName(defaultTitle);
    }
  }, []);

  useEffect(() => {
    document.title = appName;

    function setFavicon() {
      const favicon = document.getElementById('favicon');
      if (favicon) {
        favicon.href = `${process.env.PUBLIC_URL}/${faviconNew}`;
        console.log('Setting favicon to:', favicon.href);
      } else {
        console.error('Favicon element not found');
      }
    }

    setFavicon();
  }, [appName, faviconNew]);
  

  return (
    <div className="App">
      			<SignOutInactiveUser/>
      <TopHeader />
      <Navigation />
      <BackToTopPyramid />

    </div>
  );
}

export default App;
