// LanguageToggle.js
import React from "react";
import { useTranslation } from "react-i18next";
import "./LanguageToggle.css"; // Import the CSS file for styling
import usflag from "./flags/us.png";
import alflag from "./flags/al.png";
//import frflag from "./flags/fr.png";
import nmkflag from "./flags/nmk.png";
//import trkflag from "./flags/trk.png";
//import deflag from "./flags/de.png";

const LanguageToggle = () => {
	const { i18n } = useTranslation();

	const changeLanguage = (lng) => {
		i18n.changeLanguage(lng);
	};

	const getCurrentLanguageInfo = () => {
		const languageCode = i18n.language;
		// Check if the languageCode is en-US, set it to default to "Shqip" (Albanian)
		const defaultLanguageCode = languageCode === "en-US" ? "al" : languageCode;

		switch (defaultLanguageCode) {
			case "en":
				return { flag: usflag, name: "English" };
			//case "fr":				return { flag: frflag, name: "Français" };
			case "al":
				return { flag: alflag, name: "Shqip" };
			case "mk":
				return { flag: nmkflag, name: "Македонски" };
			//case "tr":				return { flag: trkflag, name: "Türkçe" };
			//case "de":				return { flag: deflag, name: "Deutsch" };
			default:
				return { flag: alflag, name: "Shqip" }; // Set "Shqip" as default
		}
	};
// eslint-disable-next-line
	const { flag, name } = getCurrentLanguageInfo();

	return (
		<div className="language-toggle-container">
			<button onClick={() => changeLanguage("mk")} className="language-button">
				<img src={nmkflag} alt="Македонски" className="language-flag" />
			</button>
			<button onClick={() => changeLanguage("al")} className="language-button">
				<img src={alflag} alt="Shqip" className="language-flag" />
			</button>
			<button onClick={() => changeLanguage("en")} className="language-button">
				<img src={usflag} alt="English" className="language-flag" />
			</button>
			{/*
			<button onClick={() => changeLanguage("de")} className="language-button">
				<img src={deflag} alt="Deutsch" className="language-flag" />
			</button>
			<button onClick={() => changeLanguage("fr")} className="language-button">
				<img src={frflag} alt="French" className="language-flag" />
			</button>

			<button onClick={() => changeLanguage("tr")} className="language-button">
				<img src={trkflag} alt="Türkçe" className="language-flag" />
	</button>*/}
		</div>
	);
};

export default LanguageToggle;
