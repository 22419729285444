import React from "react";
import PropTypes from "prop-types"; // Import prop-types
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material";


const ConfirmationDialog = ({ open, onClose, onConfirm }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Confirm Generation</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Do you want to generate the PDF with &quot;PO&quot; and &quot;JO&quot;?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Jo
        </Button>
        <Button onClick={onConfirm} color="primary">
          Po
        </Button>
      </DialogActions>
    </Dialog>
  );
};

// Add prop type validation
ConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default ConfirmationDialog;
