import React, { useEffect, useState } from "react";
import "./rezultatet_test.css";
import { useCartContext } from "../../contexts/CartContext";
import imagePaths from "../imagepaths";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { productimageURL } from "../../services/ApiService";
import { t } from "i18next";
import { Tooltip } from "@mui/material";
import Brands from "../leftcolumn/brands";
import PromoLeft from "../leftcolumn/PromoLeft";
import PromoHome from "../promohomepage/PromoHomePage";

// eslint-disable-next-line
const appStyles = process.env.REACT_APP_APP_NAME === "benfiko" 
    ? require("./benfikoRezultatet.css") 
    : require("./autostarRezultatet.css");

const reactappname = process.env.REACT_APP_APP_NAME;

function BrandImageSources(brand) {
return `./img/brandparts/${brand}.png`;
 
}


const emptyimage = "/img/results/image_search.png";

function ResultsModal() {
    const { handleAddToCartResult, results, setResults } = useCartContext([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const { t } = useTranslation();
    const { setQuery } = useCartContext();

    const handleClick = (value) => {
        const sanitizedValue = value.replace(/[^A-Za-z0-9\/.-]/gi, "");
       // console.log("Clicked value:", sanitizedValue);
       //let encodedQuery = encodeURIComponent(sanitizedValue);
        setQuery(sanitizedValue);
    };

    useEffect(() => {
        if (results === 0) setResults([]);
    }, [results, setResults]);

    const getStatusSasia = (sasia, sasia1, sasia2) => {
        if (sasia > 10) {
            return (
                <div>
                    <div className="amountaboveten">{">10"}</div>
                    {reactappname === "benfiko" && (
                        <div className="objekt">
                            {sasia1 > 0 && (
                                <Tooltip title={t("tooltiptextbenfiko1adress")} arrow>
                                <img src={imagePaths.obj1} alt="addtocartimagenostock" />
                                </Tooltip>
                            )}
                            {sasia2 > 0 && (
                                <Tooltip title={t("tooltiptextbenfiko2adress")} arrow>
                                <img src={imagePaths.obj2} alt="addtocartimagenostock" />
                                </Tooltip>
                            )}
                        </div>
                    )}
                </div>
            );
        } else if (sasia >= 1 && sasia <= 10) {
            return (
                <div>
                    <div className="amountbelowten">{sasia}</div>
                    {reactappname === "benfiko" && (
                        <div className="objekt">
                            {sasia1 > 0 && (
                                <img src={imagePaths.obj1} alt="addtocartimagenostock" />
                            )}
                            {sasia2 > 0 && (
                                <img src={imagePaths.obj2} alt="addtocartimagenostock" />
                            )}
                        </div>
                    )}
                </div>
            );
        } else {
            return (
                <div>
                    <div className="amountzero">0</div>
                </div>
            );
        }
    };

    const renderCartButton = (result, handleAddToCart) => {
        if (result.sasia <= 0) {
            return (
                <button className="outofstock">
                    <img
                        className="addtocartbuttonimage"
                        src={imagePaths.redcart}
                        alt="addtocartimagenostock"
                    />
                </button>
            );
        } else if (result.sasia < -10) {
            return (
                <button className="askforstock" onClick={() => handleAddToCart(result)}>
                    <img
                        className="addtocartbuttonimage"
                        src={imagePaths.yellowcart}
                        alt="addtocartimageask"
                    />
                </button>
            );
        } else {
            return (
                <button className="addtocart" onClick={() => handleAddToCart(result)}>
                    <img
                        className="addtocartbuttonimage"
                        src={imagePaths.greencart}
                        alt="addtocartimage"
                    />
                </button>
            );
        }
    };

    const handleAddToCart = (result) => {
        handleAddToCartResult(result);
    };

    const openModal = (oemNr, brand, id) => {
        axios
            .get(`${productimageURL}${id}`)
            .then((response) => {
                const foto = response.data.image;
                console.log("response.data", foto);
                if (foto !== undefined && foto !== "") {
                    setSelectedImage(foto);
                } else {
                    const searchUrl = `https://www.google.com/search?q=${oemNr} ${brand}&tbm=isch`;
                    const confirmed = window.confirm(`${t("imagegoogle")}`);
                    if (confirmed) {
                        window.open(searchUrl, "_blank");
                    }
                }
            })
            .catch((error) => {
                console.error("Error fetching image:", error);
            });
    };

    const closeModal = () => {
        setSelectedImage(null);
    };

    //console.log("ReactAppName: ", reactappname);
    return (
        <div>
            {results.length > 0 ? (
                <table className="searchresult1">
                    <thead className="tablehead1">
                        <tr className="headtable1">
                            <th>Brand</th>
                            
                            <th className="detail-oemnr-title">Oem Nr.</th>
							<th className="detail-productname-title">Product Name</th>
                            <th className="detail-vendornr-title">Article Nr.</th>
                            <th>Dealers Price MKD</th>
                            <th>Sales Price MKD</th>
                      
                            <th>PCS</th>
                            <th>Add to cart</th>
                        </tr>
                    </thead>

                    <tbody className="tablebody">
                        {results.map((result, index) => (
                            <React.Fragment key={index}>
                                <tr>
                                    <td className="brandi1">
                                        <img
                                            src={BrandImageSources(result.brand)}
                                            alt={result.brand}
                                        />
                                    </td>
                                    <td className="row2td" colSpan="3">
                                        <span className="produktnameoemnrvendornr">
                                            <span className="produktname1">{result.produkt_name}</span>
                                        </span>
                                        <div className="oemnrvendnr">
                                            <img
                                                className="searchoemgoogle"
                                                onClick={() =>
                                                    openModal(result.oem_nr, result.brand, result.id)
                                                }
                                                style={{ cursor: "pointer" }}
                                                src={
                                                    result.image
                                                        ? `data:image/jpeg;base64,${result.image}`
                                                        : `${emptyimage}`
                                                }
                                                alt="empty"
                                            />
                                            <span
                                                className="detail-oemnr1"
                                                onClick={() => handleClick(result.oem_nr)}
                                            >
                                                {result.oem_nr}
                                            </span>
                                            <span
                                                className="detail-vendornr1"
                                                onClick={() => handleClick(result.vend_nr)}
                                            >
                                                {result.vend_nr}
                                            </span>
                                            <img
                                                className="searchoemgoogle"
                                                onClick={() =>
                                                    openModal(result.vend_nr, result.brand, result.id)
                                                }
                                                style={{ cursor: "pointer" }}
                                                src={
                                                    result.image
                                                        ? `data:image/jpeg;base64,${result.image}`
                                                        : `${emptyimage}`
                                                }
                                                alt="empty"
                                            />
                                        </div>
                                        <div className="spandescription">
                                            <span className="description1">
                                                {result.pershkrimi}
                                            </span>
                                        </div>
                                    </td>
                                    <td className="price1">
                                        <div className="cmimishumice1">{result.cmimi_shumice}</div>
                                    </td>
                                    <td className="price1">
                                        <div className="cmimipakice1">{result.cmimi_pakice}</div>
                                    </td>
                                   
                                    <td className="amount1">
                                        {getStatusSasia(result.sasia, result.sasia_1, result.sasia_2)}
                                    </td>
                                    <td className="addtocart1">
                                        {renderCartButton(result, handleAddToCart)}
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="8" className="additional-info"></td>
                                </tr>
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>

                          
            ) : (
     
                <p>{t("noresults")}</p>
      
     
            )}
            {selectedImage && (
                <div className="rezultatet-modal-overlay" onClick={closeModal}>
                    <div className="rezultatet-modal">
                        <img
                            src={`data:image/jpeg;base64,${selectedImage}`}
                            alt=""
                            style={{ width: "900px", height: "700px" }}
                        />
                        <button className="rezultatet-close-modal" onClick={closeModal}>
                            Close
                        </button>
                    </div>
                </div>
            )}
                            <div>
                    <PromoHome/>
                </div>
        </div>
    );
}

export default ResultsModal;
