// Topheader.js

import React, { useState } from "react";
import { useAuthContext } from "../../contexts/AuthContext";
import imagePaths from "../../components/imagepaths";
//import "./topheader.css";
import LanguageToggle from "../../components/translations/LanguageToggle";
import Chat from "../../components/chat/Chat";
import Modal from "../../components/modal/Modal"; // Import your modal component
import UserInfoForm from "../../components/userinfo/UserInfoForm";
import CheckNewMessages from "../../components/chat/checkNewMessages";

// eslint-disable-next-line
const appStyles =
	process.env.REACT_APP_APP_NAME === "benfiko"
		? require("./benfikoTopheader.css")
		: require("./autostarTopheader.css");

function Topheader() {
	// console.log(appStyles)
	const company = localStorage.getItem("companyname");
	const { Logout } = useAuthContext();
	const logout = () => Logout();

	const [isChatModalOpen, setChatModalOpen] = useState(false);
	const [isInfoUserOpen, setInfoUserOpen] = useState(false);

	const openChatModal = () => {
		setChatModalOpen(true);
	};

	const closeChatModal = () => {
		setChatModalOpen(false);
	};

	const infoUserFormToggle = () => {
		setInfoUserOpen(!isInfoUserOpen);
	};

	return (
		<>
			<div className="tophead">
				<div className="company" onClick={infoUserFormToggle}>
					{company}
				</div>
				<div className="languageflags">
					<LanguageToggle />
				</div>
				<img
					src={imagePaths.logouticon}
					alt="logouticon"
					className="logouticon"
					onClick={() => logout()}
				/>
				{/*<img
					src={imagePaths.chaticon}
					alt="chaticon"
					className="chaticon"
					onClick={openChatModal}
	/>*/}
				<CheckNewMessages onClick={openChatModal} />
				<div></div>
			</div>

			{/* Render UserInfoForm component when isInfoUserOpen is true */}
			{/*isInfoUserOpen && <UserInfoForm />*/}

			{/* Render Chat component in a modal when isChatModalOpen is true */}
			{isChatModalOpen && (
				<Modal onClose={closeChatModal}>
					<Chat />
				</Modal>
			)}
		</>
	);
}

export default Topheader;
