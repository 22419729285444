import React, { useState, useEffect, useRef } from "react";
import "./Chat.css";
import axios from "axios";
import { chatUrl } from "../../services/ApiService";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Chat = () => {
	const publicUrl = process.env.PUBLIC_URL;
	const address = window.location.href;
	const appName = address.includes("autostar")
		? "Autostar"
		: publicUrl.includes("benfiko")
		? "Benfiko"
		: "TEST LOKAL";
	
	{/*	console.log(
		"public url: ",
		publicUrl,
		"address: ",
		address,
		"APP NAME: ",
		appName
	);*/}

	const [companyname] = useState(localStorage.getItem("companyname"));
	const [firmaemri] = useState(localStorage.getItem("firmaemri"));
	const [messages, setMessages] = useState([]);
	const [inputText, setInputText] = useState("");
	const [userId] = useState(+localStorage.getItem("customerid"));
	const [selectedDate, setSelectedDate] = useState(new Date());
	const messagesEndRef = useRef(null);

	const options = {
		year: "numeric",
		month: "numeric",
		day: "numeric",
	};

	const currentTime = new Date().toLocaleString("en-US", {
		timeZone: "Europe/Paris",
		hour: "2-digit",
		minute: "2-digit",
		second: "2-digit",
		hour12: false,
	});
	const fetchMessages = () => {
		const formattedDate = selectedDate.toLocaleDateString("de-DE", options);
		axios
			.get(`${chatUrl}/${userId}/1/${formattedDate}`)
			.then((response) => {
				const sortedMessages = response.data.sort((a, b) => a.id - b.ora); // Sort messages by ID
				setMessages(sortedMessages);
			})
			.catch((error) => {
				console.error("Error fetching data: ", error);
			});
	};

	useEffect(() => {
{/*		const fetchMessages = () => {
			const formattedDate = selectedDate.toLocaleDateString("de-DE", options);
			axios
				.get(`${chatUrl}/${userId}/1/${formattedDate}`)
				.then((response) => {
					const sortedMessages = response.data.sort((a, b) => a.id - b.ora); // Sort messages by ID
					setMessages(sortedMessages);
				})
				.catch((error) => {
					console.error("Error fetching data: ", error);
				});
		};*/}

		fetchMessages(); // Fetch messages immediately on mount
		const intervalId = setInterval(fetchMessages, 6000); // Fetch messages every 6 seconds
		return () => clearInterval(intervalId); // Cleanup interval on unmount
	}, [selectedDate, userId]);

	useEffect(() => {
		scrollToBottom();
	}, [messages]);

	const scrollToBottom = () => {
		messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
	};

	const handleSendMessage = () => {
		if (inputText.trim() !== "") {
			axios
				.post(`${chatUrl}`, {
					id_customer: userId,
					id_employee: 1,
					text: inputText,
					data: selectedDate.toLocaleDateString("de-DE", options),
					ora: currentTime,
					sent_by_customer: 1,
					sent_by_employee: 1,
				})
				.then((response) => {
					console.log("Message sent successfully:", response.data);
					setMessages((prevMessages) => [...prevMessages, response.data]);
					setInputText("");
					localStorage.setItem("clientmessages", JSON.stringify({
						id_customer: userId,
						id_employee: 1,
						text: inputText,
						data: selectedDate.toLocaleDateString("de-DE", options),
						ora: currentTime,
						sent_by_customer: 1,
						sent_by_employee: 1,
					}));
					localStorage.setItem("companymessages", JSON.stringify({
						id_customer: userId,
						id_employee: 1,
						text: inputText,
						data: selectedDate.toLocaleDateString("de-DE", options),
						ora: currentTime,
						sent_by_customer: 1,
						sent_by_employee: 1,
					}));
					localStorage.setItem("lastmessage", JSON.stringify({
						id_customer: userId,
						id_employee: 1,
						text: inputText,
						data: selectedDate.toLocaleDateString("de-DE", options),
						ora: currentTime,
						sent_by_customer: 1,
						sent_by_employee: 1,
					}));
					fetchMessages();

				})
				.catch((error) => {
					console.error("Error sending message: ", error);
				});
		}
	};

	const handleKeyPress = (event) => {
		if (event.key === "Enter") {
			handleSendMessage();
		}
	};

	const formatTime = (timeString) => {
		const date = new Date(timeString);
		const hours = date.getHours().toString().padStart(2, "0");
		const minutes = date.getMinutes().toString().padStart(2, "0");
		const seconds = date.getSeconds().toString().padStart(2, "0");
		return `${hours}:${minutes}:${seconds}`;
	};

	const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
		<button className="custom-datepicker-input" onClick={onClick} ref={ref}>
			{value}
		</button>
	));

	return (
		<div className="chat-container">
			<DatePicker
				selected={selectedDate}
				onChange={(date) => setSelectedDate(date)}
				dateFormat="dd.MM.yyyy"
				customInput={<CustomInput />}
				className="date-picker"
			/>
			<div className="chat-messages">
				{messages.map((message, index) => (
					<>
						<div
							key={index}
							className={`message ${
								message.sent_by_customer
									? "sent-by-customer"
									: "sent-by-employee"
							}`}
						>
							<span
								className={`message-sender ${
									message.sent_by_customer
										? "sent-by-customer"
										: "sent-by-employee"
								}`}
							>
								<div className="ora">{formatTime(message.ora)}</div>
								{message.sent_by_customer
									? companyname + ": "
									: firmaemri + ": "}
							</span>
							<div className="mesage">{message.text}</div>
						</div>
					</>
				))}
				<div ref={messagesEndRef} />
			</div>
			<div className="chat-input">
				<input
					type="text"
					placeholder="Type your message..."
					value={inputText}
					onChange={(e) => setInputText(e.target.value)}
					onKeyPress={handleKeyPress}
				/>
				<button onClick={handleSendMessage}>Send</button>
			</div>
		</div>
	);
};

export default Chat;
