import React, { useState, useEffect } from "react";
import SignIn from "../../pages/SignIn";
import { useAuthContext } from "../../contexts/AuthContext";

const SignOutInactiveUser = () => {
	const [lastActiveTime, setLastActiveTime] = useState(Date.now());
	const { Logout } = useAuthContext();
	console.log("Start check inacticity");
	useEffect(() => {
		// Function to update last active time
		const updateLastActiveTime = () => {
			setLastActiveTime(Date.now());
		};

		// Event listeners to detect user activity
		window.addEventListener("click", updateLastActiveTime);
		window.addEventListener("keydown", updateLastActiveTime);

		// Clear event listeners on unmount
		return () => {
			window.removeEventListener("click", updateLastActiveTime);
			window.removeEventListener("keydown", updateLastActiveTime);
		};
	}, []);

	useEffect(() => {
		const signOutIfInactive = () => {
			const currentTime = Date.now();
			const inactiveDuration = currentTime - lastActiveTime;
			const twoHoursInMilliseconds = 2 * 60 * 60 * 1000; // 2 hours in milliseconds
			const fiveMinutesInMilliseconds = 5 * 60 * 1000; // 5 minutes in milliseconds

			if (inactiveDuration > twoHoursInMilliseconds) {
				// Perform sign out action
				console.log(
					"User has been inactive for more than 2 hours. Signing out..."
				);
				Logout();
				// Implement sign out logic here, e.g., clearing user session, redirecting to sign-in page
			}
		};

		// Check for inactivity every minute
		const intervalId = setInterval(signOutIfInactive, 60000); // 1 minute

		// Clear interval on unmount
		return () => clearInterval(intervalId);
	}, [lastActiveTime]);

	return <div></div>;
};

export default SignOutInactiveUser;
