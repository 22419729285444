import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
//import "./css/autostarHistory.css";
import "react-tabulator/lib/styles.css";
import { historyOrderURL, itemsDetailURL } from "../services/ApiService";
import axios from "axios";
// eslint-disable-next-line
const appStyles =
	process.env.REACT_APP_APP_NAME === "benfiko"
		? require("./css/benfikoHistory.css")
		: require("./css/autostarHistory.css");

const History = () => {
	const [isLoading, setIsLoading] = useState(false); // Add this state variable
	//const { historyorder, setHistoryOrders } = useCartContext();

	const { t } = useTranslation();
	const userid = localStorage.getItem("customerid");

	//---------muaji dhe viti --------

	const currentDate = new Date();
	const initialMonth = currentDate.getMonth() + 1; // Months are zero-based, so add 1 to get the current month
	const initialYear = currentDate.getFullYear();

	const [month, setMonth] = useState(initialMonth);
	const [year, setYear] = useState(initialYear);

	const handleMonthChange = (month) => {
		setMonth(month);
		console.log("muaji ", month);
	};

	const handleYearChange = (event) => {
		setYear(event.target.value);
	};
	// Use useEffect to save the currentMonth and currentYear in session storage whenever they change

	useEffect(() => {
		//const currentDate = new Date();
		const initialMonth = month; // Months are zero-based, so add 1 to get the current month
		const initialYear = year;
	}, [month, year]);

	//-------------------button selected-------------------------

	const getButtonColor = (muaji) => {
		// Add your logic to determine the color of the selected button based on the month value
		return month === muaji ? "rgb(117, 165, 202)" : " rgb(139, 197, 242)";
	};
	const getUnderlineColor = (muaji) => {
		return month === muaji ? "3px solid red" : "none";
		// Add your logic to determine the color of the selected button based on the month value
	};
	const getUnderlineMargin = (muaji) => {
		return month === muaji ? "0 10px" : "none";
		// Add your logic to determine the color of the selected button based on the month value
	};
	//-------------------------------- muaji viti fund---------------------------

	const fetchHistoryOrders = async (month) => {
		console.log("muaji fetch: ", month);
		const urlOrderHistory =
			historyOrderURL + "-1/" + userid + "/1/+" + year + "/" + month;
		setIsLoading(true); // Set isLoading to true when starting the data fetch

		await axios
			.get(urlOrderHistory)
			.then(function (response) {
				const responseWithShumaHistoryOrder = response.data.map((it) => {
					//const dateWithoutTime = new Date(it.datetimesubmited).toLocaleDateString();
					const dateWithoutTime = new Date(
						it.datetimesubmited
					).toLocaleDateString("en-GB", {
						day: "2-digit",
						month: "2-digit",
						year: "numeric",
					});
					const parts = dateWithoutTime.split("/");
					const formattedDateString = `${parts[0]}.${parts[1]}.${parts[2]}`;
					return {
						datetimesubmited: formattedDateString,
						menyra_pagesa: it.menyra_pagesa,
						menyra_dorezimi: it.menyra_dorezimi,
						shuma: it.shuma,
						id: it.id,
					};
				});
				//localStorage.setItem("historyorderstorage",	JSON.stringify(responseWithShumaHistoryOrder)	);
				setHistoryOrder(responseWithShumaHistoryOrder);
				//setCartItems(JSON.parse(localStorage.getItem("updatedcartstorage")));
				//console.log("historyorders ", responseWithShumaHistoryOrder);

				setYear(year);
				setMonth(month);
			})
			.catch(function (error) {
				// handle error
				console.log(error);
			})
			.finally(function () {
				// always executed
				setIsLoading(false); // Set isLoading to false when the data fetch is complete
			});
	};
	const initialhistoryorder =
		JSON.parse(localStorage.getItem("historyorderstorage")) || [];
	const [historyorder, setHistoryOrder] = useState(initialhistoryorder);
	//setHistoryOrder(initialhistoryorder)

	//-------------------------detail table--------------------
	//const [selectedRowid, setSelectedRowId] = useState(null); // Step 1
	const [orderDetails, setOrderDetails] = useState();
	const [porosiId, setPorosiId] = useState();
	const [selectedRowIndex, setSelectedRowIndex] = useState(null);
	const handleRowClick = async (item, index) => {
		const modal = document.querySelector(".modal");

		const closeModalOnClickOutside = (event) => {
			if (modal && !modal.contains(event.target)) {
				modal.style.display = "block"; // Hide the modal when clicked outside
			}
		};

		setSelectedRowIndex(index);
		const itemId = item.id;
		setPorosiId(itemId);
		// Now you can use itemId as needed.
		const DetailURL = itemsDetailURL + itemId;

		await axios
			.get(DetailURL)
			.then(function (response) {
				const responseWithShumaHistoryDetail = response.data.map((it) => {
					return {
						productname: it.productname,
						oem_nr: it.upcsku,
						vendor_nr: it.vendoritemnumber,
						price: it.price,
						quantity: it.quantity,
						total: it.total,
					};
				});
				setOrderDetails(responseWithShumaHistoryDetail);
			})
			.catch(function (error) {
				// handle error
				console.log(error);
			})
			.finally(function () {
				// always executed
			});
	};

	//---------------------close details
	useEffect(() => {
		const modal = document.querySelector(".modal");

		const closeModalOnClickOutside = (event) => {
			if (modal && !modal.contains(event.target)) {
				modal.style.display = "none"; // Hide the modal when clicked outside
			}
		};

		// Add a click event listener to the document
		document.addEventListener("click", closeModalOnClickOutside);

		return () => {
			// Remove the event listener when the component unmounts
			document.removeEventListener("click", closeModalOnClickOutside);
		};
	}, []);
	//------------------------------------------------------------------------
	const [isScrollable, setScrollable] = useState(false);
	const toggleScrollable = () => {
		setScrollable(!isScrollable);
	};

	//---------------------------
	const renderSelectedRowData = () => {
		// Step 2: Create a new table with the selected row data
		if (orderDetails) {
			return (
				<div
					className={`modal ${isScrollable ? "scrollable" : ""}`}
					onClick={toggleScrollable}
				>
					<table>
						<thead>
							<tr>
								<th>Product Name</th>
								<th className="detail-oemnr">Oem Nr.</th>
								<th className="detail-vendornr">Vendor Nr.</th>
								<th>Price</th>
								<th>quantity</th>
								<th>Total</th>
							</tr>
						</thead>
						<tbody>
							{orderDetails.map((detail, index) => (
								<tr key={index}>
									<td>{detail.productname}</td>
									<td className="detail-oemnr">{detail.oem_nr}</td>
									<td className="detail-vendornr">{detail.vendor_nr}</td>
									<td>{detail.price}</td>
									<td>{detail.quantity}</td>
									<td>{detail.total}</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			);
		}
	};

	//---------------------------------------------------------

	return (
		<div className="historikunalt">
			{isLoading ? (
				<div className="loading-circle"></div>
			) : (
				<div>
					{/* muaji dhe viti*/}
					<div className="selectedyearandmonth">
						<div>
							<input
								className="shkruajviti"
								type="text"
								placeholder="Enter Year"
								value={year}
								onChange={handleYearChange}
							/>
						</div>
						<div id="buttons-container">
							<button
								onClick={fetchHistoryOrders}
								style={{
									backgroundColor: getButtonColor(0),
									borderBottom: getUnderlineColor(0),
									padding: getUnderlineMargin(0),
								}}
							>
								Krejt Vitin
							</button>
							<button
								onClick={() => {
									handleMonthChange(1);
									fetchHistoryOrders(1);
								}}
								style={{
									backgroundColor: getButtonColor(1),
									borderBottom: getUnderlineColor(1),
									padding: getUnderlineMargin(1),
									// Add underline style
								}}
							>
								1
							</button>
							<button
								onClick={() => {
									handleMonthChange(2);
									fetchHistoryOrders(2);
								}}
								style={{
									backgroundColor: getButtonColor(2),
									borderBottom: getUnderlineColor(2),
									padding: getUnderlineMargin(2),
								}}
							>
								2
							</button>
							<button
								onClick={() => {
									handleMonthChange(3);
									fetchHistoryOrders(3);
								}}
								style={{
									backgroundColor: getButtonColor(3),
									borderBottom: getUnderlineColor(3),
									padding: getUnderlineMargin(3),
								}}
							>
								3
							</button>
							<button
								onClick={() => {
									handleMonthChange(4);
									fetchHistoryOrders(4);
								}}
								style={{
									backgroundColor: getButtonColor(4),
									borderBottom: getUnderlineColor(4),
									padding: getUnderlineMargin(4),
								}}
							>
								4
							</button>
							<button
								onClick={() => {
									handleMonthChange(5);
									fetchHistoryOrders(5);
								}}
								style={{
									backgroundColor: getButtonColor(5),
									borderBottom: getUnderlineColor(5),
									padding: getUnderlineMargin(5),
								}}
							>
								5
							</button>
							<button
								onClick={() => {
									handleMonthChange(6);
									fetchHistoryOrders(6);
								}}
								style={{
									backgroundColor: getButtonColor(6),
									borderBottom: getUnderlineColor(6),
									padding: getUnderlineMargin(6),
								}}
							>
								6
							</button>
							<button
								onClick={() => {
									handleMonthChange(7);
									fetchHistoryOrders(7);
								}}
								style={{
									backgroundColor: getButtonColor(7),
									borderBottom: getUnderlineColor(7),
									padding: getUnderlineMargin(7),
								}}
							>
								7
							</button>
							<button
								onClick={() => {
									handleMonthChange(8);
									fetchHistoryOrders(8);
								}}
								style={{
									backgroundColor: getButtonColor(8),
									borderBottom: getUnderlineColor(8),
									padding: getUnderlineMargin(8),
								}}
							>
								8
							</button>
							<button
								onClick={() => {
									handleMonthChange(9);
									fetchHistoryOrders(9);
								}}
								style={{
									backgroundColor: getButtonColor(9),
									borderBottom: getUnderlineColor(9),
									padding: getUnderlineMargin(9),
								}}
							>
								9
							</button>
							<button
								onClick={() => {
									handleMonthChange(10);
									fetchHistoryOrders(10);
								}}
								style={{
									backgroundColor: getButtonColor(10),
									borderBottom: getUnderlineColor(10),
									padding: getUnderlineMargin(10),
								}}
							>
								10
							</button>
							<button
								onClick={() => {
									handleMonthChange(11);
									fetchHistoryOrders(11);
								}}
								style={{
									backgroundColor: getButtonColor(11),
									borderBottom: getUnderlineColor(11),
									padding: getUnderlineMargin(11),
								}}
							>
								11
							</button>
							<button
								onClick={() => {
									handleMonthChange(12);
									fetchHistoryOrders(12);
								}}
								style={{
									backgroundColor: getButtonColor(12),
									borderBottom: getUnderlineColor(12),
									padding: getUnderlineMargin(12),
								}}
							>
								12
							</button>
							{/*
							<div>
								<button className="btnfiltrobtn" onClick={fetchHistoryOrders}>
									Filtro Vitin
								</button>
							</div>
								*/}{" "}
						</div>
						{/*<div>
							<p>Selected Month: {month}</p>
						</div>
						<div>
							<p>Entered Year: {year}</p>
						</div>
			*/}

						{/* perfundomn muaji dhe viti*/}
					</div>
					{historyorder.length > 0 && (
						<div className="historiku">
							<div className="cart_titulli1">{t("orders")}</div>
							<table>
								<thead>
									<tr>
										<th>Order ID</th>
										<th>Data</th>
										<th>Meenyra pageses</th>
										<th>Menyra dorezimit</th>
										<th>Shuma</th>
									</tr>
								</thead>
								<tbody>
									{historyorder.map((item, index) => (
										<tr
											key={index}
											onClick={() => handleRowClick(item, index)}
											className={
												selectedRowIndex === index
													? "selected-row"
													: "modal-row"
											}
										>
											<td>{item.id}</td>
											<td>{item.datetimesubmited}</td>
											<td>{item.menyra_pagesa}</td>
											<td>{item.menyra_dorezimi}</td>
											<td>{item.shuma}</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					)}
					{renderSelectedRowData()}{" "}
					{/* Step 2: Display the selected row data */}
				</div>
			)}
		</div>
	);
};

export default History;
