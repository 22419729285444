import React, { useEffect, useState } from "react";
import { Dialog, DialogTitle, DialogContent, Button } from "@mui/material";
import CartPDFGenerator from "../../components/pdfCreator/CartPDFGenerator";
import "./CheckoutForm.css";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useCartContext } from "../../contexts/CartContext";
import {
	customersURL,
	dealerordersURL,
	itemsDetailURL,
} from "../../services/ApiService";

const CheckoutForm = ({ open, handleClose }) => {
	const { t } = useTranslation();
	const { pagesaMenyra, setPagesaMenyra, dergesaMenyra, setDergesaMenyra } =
		useCartContext();
	//const [company, setCompany] = useState(localStorage.getItem("company") || "");
	const [idDealer] = useState(localStorage.getItem("customerid") || "");
	const [dateSubmitted] = useState(
		new Date().toLocaleString("en-US", {
			timeZone: "Europe/Paris",
			hour: "2-digit",
			minute: "2-digit",
			hour12: false,
		})
	);

	const { cartItems, setCartItems } = useCartContext();

	// Update local state when context values change
	useEffect(() => {
		setPagesaMenyra(pagesaMenyra);
		setDergesaMenyra(dergesaMenyra);
	}, [pagesaMenyra, dergesaMenyra, setDergesaMenyra, setPagesaMenyra]);
	// console.log("pagesa: ",pagesaMenyra, "dergesa maneyra: ", dergesaMenyra)

	const [dealerOrders, setDealerOrders] = useState({
		datetimestarted: dateSubmitted,
		datetimesubmited: dateSubmitted,
		id_dealer: idDealer,
		accepted: 0,
		submitted: 1,
		menyra_pagesa: pagesaMenyra,
		menyra_dorezimi: dergesaMenyra,
	});
	useEffect(() => {
		setDealerOrders((prevState) => ({
			...prevState,
			menyra_pagesa: pagesaMenyra,
			menyra_dorezimi: dergesaMenyra,
		}));
	}, [pagesaMenyra, dergesaMenyra]);

	const dealerOrdersJSON = JSON.stringify(dealerOrders);
	//const dealerorderurl = "https://127.0.0.1:3010/orders";
	//const dealerordersdetailURL="https://127.0.0.1:3010/items";
	/*
	const handleCheckout = () => {
		localStorage.removeItem("updatedcartstorage");
		handleClose();
	};
*/
const checkOutOrders = async () => {
    try {
        // const data = JSON.parse(localStorage.getItem("updatedcartstorage"));

        const response = await axios.post(dealerordersURL, dealerOrdersJSON, {
            headers: {
                "Content-Type": "application/json",
            },
        });

        console.log("Data posted successfully:", response.data);
        const iddealersorders = response.data.insertedId;
        console.log("Inserted ID:", iddealersorders);

        // Update cart items with the inserted ID
        const updatedCartItems = cartItems.map((item) => ({
            id_dealersorders: iddealersorders,
            id_product: item.id,
            quantity: item.quantity,
            price: item.cmimi_shumice,
        }));
        console.log(updatedCartItems);
        const updatedCartItemsJSON = JSON.stringify(updatedCartItems);

        console.log(updatedCartItemsJSON);
        // Post updated cart items immediately
        const itemsResponse = await axios.post(itemsDetailURL, updatedCartItemsJSON, {
            headers: {
                "Content-Type": "application/json",
            },
        });

        console.log("Updated cart items posted successfully:", itemsResponse.data);

        // Update customer's neworders
        const patchResponse = await axios.patch(customersURL + idDealer + "/neworder", []);

        console.log("Updated neworders to 1", patchResponse.data);

        setCartItems([]);
        localStorage.removeItem("updatedcartstorage");

        // Close dialog or perform other actions
        handleClose();
    } catch (error) {
        console.error("Error during checkout:", error);
        // Handle the error here, if needed
    }
};


	return (
		<div className="checkoutform">
			<Dialog className="checkoutform" open={open} onClose={handleClose}>
				<DialogTitle>
					{t("orders")}
					<CartPDFGenerator />
				</DialogTitle>
				<DialogContent>
					<Button variant="contained" color="primary" onClick={checkOutOrders}>
						{t("checkout")}
					</Button>
				</DialogContent>
				<div className="cartpdfgenerator"></div>
			</Dialog>
		</div>
	);
};

CheckoutForm.propTypes = {
	open: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
};

export default CheckoutForm;
